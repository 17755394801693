body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #252525 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.aws-btn {
  --button-default-height: 44px;
  --button-default-font-size: 14px;
  --button-default-border-radius: 25px;
  --button-horizontal-padding: 16px;
  --button-raise-level: 5px;
  --button-hover-pressure: 0.5;
  --transform-speed: 0.15s;
  --button-primary-color: #ff7452;
  --button-primary-color-dark: #ff5f38;
  --button-primary-color-light: #ffffff;
  --button-primary-color-hover: #ff7857;
  --button-primary-color-active: #ff5f38;
  --button-primary-border: none;
  --button-secondary-color: #f2f6f9;
  --button-secondary-color-dark: #1360a4;
  --button-secondary-color-light: #1e88e5;
  --button-secondary-color-hover: #e1eaf1;
  --button-secondary-color-active: #cfdee9;
  --button-secondary-border: 2px solid #1e88e5;
  --button-anchor-color: #0e4f88;
  --button-anchor-color-dark: #072743;
  --button-anchor-color-light: #ffffff;
  --button-anchor-color-hover: #0d4a7f;
  --button-anchor-color-active: #0c4271;
  --button-anchor-border: none;
  --button-danger-color: #c42e2e;
  --button-danger-color-dark: #861f1f;
  --button-danger-color-light: #ffffff;
  --button-danger-color-hover: #bc2c2c;
  --button-danger-color-active: #af2929;
  --button-danger-border: none;
}